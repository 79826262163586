// // 子路由要渲染到父路由的<router-view />中
const RouteView = {
  name: "RouteView",
  render: (h) => h("router-view"),
};
// // 定义页面路由
// // 现场管理系统
const constantRouterComponents = {
  // 登陆
  Login: () => import("@/views/login"),
  // 工地管理
  BasicLayout: () => import("@/layouts/BasicLayout"),

  // 数据统计
  DailySheet: () => import("@/views/dataStatistics/DailySheet.vue"),
  SiteImage: () => import("@/views/dataStatistics/SiteImage.vue"), //工地形象统计
  AttendStatistics: () => import("@/views/dataStatistics/AttendStatistics.vue"), //考勤统计
  HomeMaster: () => import("@/views/home/HomeMaster.vue"), //我要掌握

  // 工人维度
  WorkerInfo: () => import("@/views/home/worker/WorkerInfo.vue"), //工人基本信息
  ProjectExperience: () => import("@/views/home/worker/ProjectExperience.vue"), //工人项目经历
  EvaluationRecord: () => import("@/views/home/worker/EvaluationRecord.vue"), //工人评价记录
  OrderRank: () => import("@/views/home/worker/OrderRank.vue"), //工人等级/勋章
  BreakRecord: () => import("@/views/home/worker/BreakRecord.vue"), //工人违章记录
  BecomplainedRecord: () =>
    import("@/views/home/worker/BecomplainedRecord.vue"), //工人被投诉记录
  CleanChange: () => import("@/views/home/worker/CleanChange.vue"), //工人施工工地整洁度变化
  AcceptanceRecord: () => import("@/views/home/worker/AcceptanceRecord.vue"), //工人开工完工验收记录
  VisitorVisit: () => import("@/views/home/visitor/VisitorVisit.vue"), //陌生访客到访情况

  // 工地维度
  SiteInfo: () => import("@/views/home/site/SiteInfo.vue"), //工地维度-基本信息
  SiteDuration: () => import("@/views/home/site/SiteDuration.vue"), //工地维度-工期信息
  SiteAttendance: () => import("@/views/home/site/SiteAttendance.vue"), //工地维度-施工工人考勤信息
  SitePatrol: () => import("@/views/home/site/SitePatrol.vue"), //工地维度-监管人员巡查信息
  SiteStylistdirect: () => import("@/views/home/site/SiteStylistdirect.vue"), //工地维度-设计人员现场指导信息
  SiteAccept: () => import("@/views/home/site/SiteAccept.vue"), //工地维度-现场验收信息
  SiteRequiredpatrol: () => import("@/views/home/site/SiteRequiredpatrol.vue"), //工地维度-按照规定巡查
  SiteRequireaccept: () => import("@/views/home/site/SiteRequireaccept.vue"), //工地维度-按照规定现场验收
  SiteRemotepatrol: () => import("@/views/home/site/SiteRemotepatrol.vue"), //工地维度-远程巡查信息
  SiteVidicon: () => import("@/views/home/site/SiteVidicon.vue"), //工地维度-工地摄像机在线分析
  SiteBreak: () => import("@/views/home/site/SiteBreak.vue"), //工地维度-工地违章信息
  SiteClean: () => import("@/views/home/site/SiteClean.vue"), //工地维度-工地整洁度变化趋势
  SiteComplaint: () => import("@/views/home/site/SiteComplaint.vue"), //工地维度-项目投诉记录

  // 业主维度
  OwnerInfo: () => import("@/views/home/owner/OwnerInfo.vue"), //业主维度-业主资料
  OwnerVideoaccess: () => import("@/views/home/owner/OwnerVideoaccess.vue"), //业主维度-视频访问记录
  OwnerVisit: () => import("@/views/home/owner/OwnerVisit.vue"), //业主维度-现场到访记录
  OwnerInteract: () => import("@/views/home/owner/OwnerInteract.vue"), //业主维度-业主互动记录
  OwnerFeedback: () => import("@/views/home/owner/OwnerFeedback.vue"), //业主维度-业主信息反馈(含投诉)
  OwnerPromise: () => import("@/views/home/owner/OwnerPromise.vue"), //业主维度-业主承诺管理
  OwnerProjectwarn: () => import("@/views/home/owner/OwnerProjectwarn.vue"), //业主维度-业主项目工期预警记录
  OwnerQualitywarn: () => import("@/views/home/owner/OwnerQualitywarn.vue"), //业主维度-施工质量预警
  OwnerWarnrecord: () => import("@/views/home/owner/OwnerWarnrecord.vue"), //业主维度-工地报警记录

  // 管理维度
  ManageSupervise: () => import("@/views/home/manage/ManageSupervise.vue"), //管理维度-监管人员督察
  ManageUnit: () => import("@/views/home/manage/ManageUnit.vue"), //管理维度-管理下基层
  ManageInspect: () => import("@/views/home/manage/ManageInspect.vue"), //管理维度-规定动作核查
  ManageComplaint: () => import("@/views/home/manage/ManageComplaint.vue"), //管理维度-业主反馈/投诉
  ManageFindproblem: () => import("@/views/home/manage/ManageFindproblem.vue"), //管理维度-发现问题
  ManageRework: () => import("@/views/home/manage/ManageRework.vue"), //管理维度-返工分析
  ManagePostpone: () => import("@/views/home/manage/ManagePostpone.vue"), //管理维度-延期分析
  ManageQuality: () => import("@/views/home/manage/ManageQuality.vue"), //管理维度-质量分析
};

export const asyncRouterMap = [
  // export const constantRoutes = [
  // 首页
  {
    path: "",
    redirect: "/HomeMaster",
    meta: { title: "首页", icon: "ep:tools" },
    component: RouteView,
    activeMenu: "/HomeMaster",
    // path: "/HomeMaster",
    name: "HomeMaster",
    // meta: { title: "首页", showMenu: false, memoryRouter: false },
    // component: constantRouterComponents.HomeMaster,
    // component: () => import("@/views/home/home.vue"),
    children: [
      {
        path: "/HomeMaster",
        name: "HomeMaster",
        meta: {
          title: "首页",
          icon: "tools",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.HomeMaster,
      },
      {
        path: "/WorkerInfo",
        name: "WorkerInfo",
        meta: {
          title: "工人基本信息",
          showMenu: false,
          memoryRouter: false, //是否记忆当前路由为常用工具
        },
        hidden: true,
        component: constantRouterComponents.WorkerInfo,
      },
      {
        path: "/ProjectExperience",
        name: "ProjectExperience",
        meta: {
          title: "项目经历",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ProjectExperience,
      },
      {
        path: "/EvaluationRecord",
        name: "EvaluationRecord",
        meta: {
          title: "评价记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.EvaluationRecord,
      },
      {
        path: "/OrderRank",
        name: "OrderRank",
        meta: {
          title: "等级/勋章",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OrderRank,
      },
      {
        path: "/BreakRecord",
        name: "BreakRecord",
        meta: {
          title: "违章记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.BreakRecord,
      },
      {
        path: "/BecomplainedRecord",
        name: "BecomplainedRecord",
        meta: {
          title: "被投诉记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.BecomplainedRecord,
      },
      {
        path: "/CleanChange",
        name: "CleanChange",
        meta: {
          title: "施工工地整洁度变化",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.CleanChange,
      },
      {
        path: "/AcceptanceRecord",
        name: "AcceptanceRecord",
        meta: {
          title: "开工完工验收记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.AcceptanceRecord,
      },
      {
        path: "/VisitorVisit",
        name: "VisitorVisit",
        meta: {
          title: "陌生访客到访情况",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.VisitorVisit,
      },
      {
        path: "/SiteInfo",
        name: "SiteInfo",
        meta: {
          title: "基本信息",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteInfo,
      },
      {
        path: "/SiteDuration",
        name: "SiteDuration",
        meta: {
          title: "工期信息",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteDuration,
      },
      {
        path: "/SiteAttendance",
        name: "SiteAttendance",
        meta: {
          title: "施工工人考勤信息",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteAttendance,
      },
      {
        path: "/SitePatrol",
        name: "SitePatrol",
        meta: {
          title: "监管人员巡查信息",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SitePatrol,
      },
      {
        path: "/SiteStylistdirect",
        name: "SiteStylistdirect",
        meta: {
          title: "设计人员现场指导信息",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteStylistdirect,
      },
      {
        path: "/SiteAccept",
        name: "SiteAccept",
        meta: {
          title: "现场验收信息",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteAccept,
      },
      {
        path: "/SiteRequiredpatrol",
        name: "SiteRequiredpatrol",
        meta: {
          title: "按照规定巡查",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteRequiredpatrol,
      },
      {
        path: "/SiteRequireaccept",
        name: "SiteRequireaccept",
        meta: {
          title: "按照规定现场验收",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteRequireaccept,
      },
      {
        path: "/SiteRemotepatrol",
        name: "SiteRemotepatrol",
        meta: {
          title: "远程巡查信息",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteRemotepatrol,
      },
      {
        path: "/SiteVidicon",
        name: "SiteVidicon",
        meta: {
          title: "工地摄像机在线分析",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteVidicon,
      },
      {
        path: "/SiteBreak",
        name: "SiteBreak",
        meta: {
          title: "工地违章信息",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteBreak,
      },
      {
        path: "/SiteClean",
        name: "SiteClean",
        meta: {
          title: "工地整洁度变化趋势",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteClean,
      },
      {
        path: "/SiteComplaint",
        name: "SiteComplaint",
        meta: {
          title: "项目投诉记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteComplaint,
      },
      {
        path: "/OwnerInfo",
        name: "OwnerInfo",
        meta: {
          title: "业主资料",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerInfo,
      },
      {
        path: "/OwnerVideoaccess",
        name: "OwnerVideoaccess",
        meta: {
          title: "视频访问记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerVideoaccess,
      },
      {
        path: "/OwnerVisit",
        name: "OwnerVisit",
        meta: {
          title: "现场到访记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerVisit,
      },
      {
        path: "/OwnerInteract",
        name: "OwnerInteract",
        meta: {
          title: "业主互动记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerInteract,
      },
      {
        path: "/OwnerFeedback",
        name: "OwnerFeedback",
        meta: {
          title: "业主信息反馈(含投诉)",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerFeedback,
      },
      {
        path: "/OwnerPromise",
        name: "OwnerPromise",
        meta: {
          title: "业主承诺管理",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerPromise,
      },
      {
        path: "/OwnerProjectwarn",
        name: "OwnerProjectwarn",
        meta: {
          title: "业主项目工期预警记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerProjectwarn,
      },
      {
        path: "/OwnerQualitywarn",
        name: "OwnerQualitywarn",
        meta: {
          title: "施工质量预警",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerQualitywarn,
      },
      {
        path: "/OwnerWarnrecord",
        name: "OwnerWarnrecord",
        meta: {
          title: "工地报警记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerWarnrecord,
      },
      // 管理维度
      {
        path: "/ManageSupervise",
        name: "ManageSupervise",
        meta: {
          title: "监管人员督察",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ManageSupervise,
      },
      {
        path: "/ManageUnit",
        name: "ManageUnit",
        meta: {
          title: "管理下基层",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ManageUnit,
      },
      {
        path: "/ManageInspect",
        name: "ManageInspect",
        meta: {
          title: "规定动作核查",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ManageInspect,
      },
      {
        path: "/ManageComplaint",
        name: "ManageComplaint",
        meta: {
          title: "业主反馈/投诉",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ManageComplaint,
      },
      {
        path: "/ManageFindproblem",
        name: "ManageFindproblem",
        meta: {
          title: "发现问题",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ManageFindproblem,
      },
      {
        path: "/ManageRework",
        name: "ManageRework",
        meta: {
          title: "返工分析",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ManageRework,
      },
      {
        path: "/ManagePostpone",
        name: "ManagePostpone",
        meta: {
          title: "延期分析",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ManagePostpone,
      },
      {
        path: "/ManageQuality",
        name: "ManageQuality",
        meta: {
          title: "质量分析",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ManageQuality,
      },
    ],
  },
];

// /**
//  * 基础路由
//  * @type { *[] }
//  */
export const constantRouterMap = [
  {
    path: "/",
    name: "BasicLayout",
    component: constantRouterComponents.BasicLayout,
    redirect: "/login",
    children: asyncRouterMap,
    // children: constantRoutes,
  },
  {
    path: "/login",
    name: "login",
    component: constantRouterComponents.Login,
  },
  {
    path: "/DataView",
    name: "DataView",
    component: () => import("@/views/datav/index.vue"),
  },
  {
    path: "/PatrolView",
    name: "PatrolView",
    component: () => import("@/views/inspection/index.vue"),
  },
  {
    path: "/PatrolDetail",
    name: "PatrolView",
    component: () => import("@/views/inspection/detail.vue"),
  },
  // {
  //   path: "/Inspection",
  //   name: "Inspection",
  //   component: () => import("@/views/inspection/index.vue"),
  // },
  {
    path: "/CaseFieldView",
    name: "CaseFieldView",
    component: () => import("@/views/caseField/index.vue"),
  },
  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: "fail" */ "@/views/exception/404"),
  },
  {
    path: "/401",
    component: () =>
      import(/* webpackChunkName: "fail" */ "@/views/exception/401"),
  },
  {
    path: "/500",
    component: () =>
      import(/* webpackChunkName: "fail" */ "@/views/exception/500"),
  },
  // {
  //   path: "*",
  //   redirect: "/404",
  //   hidden: true,
  // },
  {
    path: "/customPreviewView",
    name: "customPreviewView",
    component: () => import("@/views/customField/customPreview.vue"),
  },
  {
    path: "/BoardTotalView",
    name: "BoardTotalView",
    component: () => import("@/views/adminBoard/BoardTotal.vue"),
  },
  {
    path: "/ExhibitionBoardView",
    name: "ExhibitionBoardView",
    component: () => import("@/views/exhibitionboard/ExhibitionBoard.vue"),
  },
  {
    path: "/MapFieldboardView",
    name: "MapFieldboard",
    component: () => import("@/views/mapField"),
  },
  {
    path: "/VideoRecordView",
    name: "videoRecord",
    component: () => import("@/views/videoRecord"),
  },
];
