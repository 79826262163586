export const urlConfig = {
  baseUrl: "https://ai1.4all.cn", //通用平台
  // baseUrl: "http://39.106.139.30/api", //通用平台
  // baseUrl: "https://tsyzf.4all.cn", //唐山业之峰
  // baseUrl: "http://192.168.0.208:8763",
  // baseUrl: "http://localhost:8080/test",
};
// export const urlConfig = {
// baseUrl: "http://localhost:8763",
// }
